<template>
  <!-- 新增充值页面 -->
  <div class="addTopUpPageBox page-info-content">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :inline="true"
      label-width="130px"
      label-position="left"
      class="demo-ruleForm page-form form-item-row"
    >
      <el-form-item label="会员名称" prop="cmpId">
        <el-select
          v-model="ruleForm.cmpId"
          class="cmpNameSearch"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键字搜索"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in cmpNameArr"
            :key="item.cmpCode"
            :label="item.cmpName"
            :value="item.cmpCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="结算银行" prop="settleBankId">
        <el-select v-model="ruleForm.settleBankId" class="cmpNameSearch" placeholder="请选择搜索">
          <!-- 取的是字典项 -->
          <el-option
            v-for="item in $store.getters.getDictionaryItem('settleBankName')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="充值金额(元)" prop="amount">
        <el-input-number v-model="ruleForm.amount" :precision="2" :controls="false" placeholder="请输入充值金额" />
      </el-form-item>
      <el-form-item label="渠道" prop="operPlat">
        <el-select v-model="ruleForm.operPlat" placeholder="请选择">
          <el-option label="平台" value="01" />
          <el-option label="银行" value="02" />
        </el-select>
      </el-form-item>
      <el-form-item label="支付方式" prop="payWay">
        <el-select v-model="ruleForm.payWay" placeholder="请选择">
          <el-option label="现金" value="011" />
          <el-option label="网银转账" value="012" />
          <el-option label="银行接口" value="021" />
          <el-option label="柜台" value="022" />
        </el-select>
      </el-form-item>
      <el-form-item label="银行交易流水号" prop="bankBusiNo">
        <el-input v-model="ruleForm.bankBusiNo" placeholder="请输入银行交易流水号" />
      </el-form-item>
      <el-form-item label="汇款银行" prop="openBankName">
        <el-input v-model="ruleForm.openBankName" placeholder="请输入汇款银行" />
      </el-form-item>
      <el-form-item label="汇款账户名称" prop="openAccName">
        <el-input v-model="ruleForm.openAccName" placeholder="请输入汇款账户名称" />
      </el-form-item>
      <el-form-item label="汇款账号" prop="openAccNo">
        <el-input v-model="ruleForm.openAccNo" placeholder="请输入汇款账号" />
      </el-form-item>
      <el-form-item label="汇款网点" prop="openBankNet">
        <el-input v-model="ruleForm.openBankNet" placeholder="请输入汇款网点" />
      </el-form-item>
      <el-form-item label="经办人" prop="operMan">
        <el-input v-model="ruleForm.operMan" placeholder="请输入经办人" />
      </el-form-item>
      <el-form-item label="充值时间" prop="payTime">
        <el-date-picker
          v-model="ruleForm.payTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择日期时间"
          :picker-options="pickerOptionsStart"
        />
      </el-form-item>
      <el-form-item class="lastOne" label="备注">
        <el-input v-model="ruleForm.remark" type="textarea" maxlength="200" show-word-limit :rows="3" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <div class="buttonCombination">
      <el-button type="primary" size="small" class="widen-button" @click="submitForm('ruleForm')">
        确定
      </el-button>
      <el-button size="small" class="widen-button" @click="resetForm('ruleForm')">
        重置
      </el-button>
    </div>
  </div>
</template>

<script>
import { inoutLogPayAdd } from '../../../api/accountManagementSystem'
import { getCmpBaseinfoList } from '../../../api/memberManagement'
import { formPattern } from '@/utils/matchFormRegex'
export default {
  data() {
    return {
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      ruleForm: {},
      rules: {
        cmpId: [
          { required: true, message: '请输入选择一个会员', trigger: 'change' }
        ],
        settleBankId: [
          { required: true, message: '请选择结算银行', trigger: 'change' }
        ],
        amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
          { ...formPattern.stwentyBeforeDecimalPlaces },
          { ...formPattern.twoDecimalPlaces }
        ],
        operPlat: [
          { required: true, message: '请选择渠道', trigger: 'change' }
        ],
        payWay: [
          { required: true, message: '请选择支付方式', trigger: 'change' }
        ],
        payTime: [
          { required: true, message: '请选择充值时间', trigger: 'change' }
        ],
        bankBusiNo: [
          { min: 1, max: 32, message: '不超过32个字符', trigger: 'blur' }
        ],
        openBankName: [
          { min: 1, max: 100, message: '不超过100个字符', trigger: 'blur' }
        ],
        openAccName: [
          { min: 1, max: 20, message: '不超过20个字符', trigger: 'blur' }
        ],
        openAccNo: [
          { min: 1, max: 20, message: '不超过20个字符', trigger: 'blur' }
        ],
        openBankNet: [
          { min: 1, max: 100, message: '不超过100个字符', trigger: 'blur' }
        ],
        operMan: [
          { min: 1, max: 50, message: '不超过50个字符', trigger: 'blur' }
        ],
        remark: [
          { min: 1, max: 1000, message: '不超过1000个字符', trigger: 'blur' }
        ]
      },
      cmpNameArr: [],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/accountManagementSystem/topUpManagement', name: '充值管理' }, { path: '', name: '新增充值' }]
    }
  },
  mounted() {
  },
  methods: {
    // 联动搜搜货主列表
    remoteMethod(value) {
      if (!value) return
      getCmpBaseinfoList(value, res => {
        this.cmpNameArr = [...res.data]
      })
    },
    // 点击确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 获取会员名称
          this.cmpNameArr.forEach(item => {
            if (item.cmpCode === this.ruleForm.cmpId) { this.ruleForm.cmpName = item.cmpName }
          })
          // 获取结算银行的名称
          const arr = this.$store.getters.getDictionaryItem('settleBankName')
          arr.forEach(item => {
            if (item.dictId === this.ruleForm.settleBankId) { this.ruleForm.settleBankName = item.dictName }
          })
          inoutLogPayAdd(this.ruleForm, () => {
            this.$message.success('新增成功！')
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          })
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }

}
</script>

<style lang="scss">
.addTopUpPageBox {
  .el-form {
    .lastOne {
      display: block;
    }
  }
  .buttonCombination {
    text-align: center;
  }
  .el-form .lastOne {
    width: 100%;
    .el-form-item__content {
      width: 85%;
    }
  }
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  .table td {
    border: 1px solid #ededed;
    text-align: center;
  }
  .table td .el-input {
    width: 160px;
  }
  .table td i {
    font-size: 26px;
    cursor: pointer;
    line-height: 40px;
  }
  .table td .el-input .el-input__inner {
    border: 0;
    text-align: center;
  }
}
</style>
